@import "@cs/styles";

.banner {
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99;
  box-shadow: none;
  box-sizing: border-box;
  top: 0;
  margin: 0 auto;
  position: relative;
  width: 100%;
  padding: 0;
  white-space: nowrap;

  background: #6231ec;

  .innerTopnav {
    max-width: var(--width);
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: auto;
    height: 100%;

    .bigLogo {
      display: none;
    }
  }

  button {
    cursor: pointer;
    color: #fff;
    line-height: 1;
    margin-right: 6px;
  }
}

.bannerText {
  @include Text;
  color: white;
  font-size: 14px;
  line-height: 1.1;
  margin: auto;
  text-align: center;
  word-break: break-word;
  white-space: normal;
  padding: 6px;

  @include media(">desktop") {
    font-size: 1em;
    padding: 10px;
  }
}

.bannerLink {
  color: white !important;
  font-weight: 600;

  &:before {
    background-color: white;
    color: white;
    transition: all 0.3s ease-in-out;
  }
}

.header {
  width: 100%;
  height: 72px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.headerContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: var(--width);
  margin-inline: auto;
}

.headerRightContainer {
  display: flex;
  gap: 6px;
}

.navBarContainer {
  display: none;
  gap: 6px;

  @include media(">desktop") {
    display: flex;
    align-items: center;
  }
}

.mobileNavigation {
  display: flex;

  @include media(">desktop") {
    display: none;
  }
}
