@import "@cs/styles";

.anchor {
  display: flex;
  align-items: center;
  margin-block: 4px;
}

.smallLogo {
  display: inline-flex;
  padding-inline: 10px;

  @include media(">desktop") {
    display: none;
  }
}

.bigLogo {
  display: none;

  @include media(">desktop") {
    display: flex;
  }
}
