@import "./common/exports.scss";
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Work+Sans:wght@300;400;500;600;700&display=swap");

:root {
  --width: 98vw;

  @include media(">phone", "<=tablet") {
    --width: 96vw;
  }
  @include media(">tablet", "<=desktop") {
    --width: 95vw;
  }
  @include media(">desktop", "<=bigDesktop") {
    --width: min(80vw, 1240px);
  }
  @include media(">bigDesktop") {
    --width: min(75vw, 1240px);
  }
}

html,
body {
  padding: 0;
  margin: 0;
  font-family: "Work Sans", "Roboto", sans-serif;
  color: $darkText;
  -webkit-font-smoothing: antialiased;
  font-display: swap;
}

[lang="ky"] html,
[lang="ky"] body {
  font-family: "Roboto", "Work Sans", sans-serif;
}

a {
  color: inherit;
  text-decoration: none;
}

button {
  font-family: inherit;
  border: none;
  background: none;
  font-size: inherit;
}

ul,
ol,
li {
  list-style: none;
  padding-left: 0;
  margin: 0;
}

*:active {
  outline: none;
}

a:focus,
button:focus,
input:focus,
select:focus {
  outline-style: solid;
  outline-width: 2px;
  outline-offset: 2px;
  outline-color: $purple2;
  // border-radius: 8px;
}

// Trick to remove outline while clicking elements with mouse
a:hover,
button:hover {
  outline-style: none;
}

.width {
  width: var(--width);
  margin: 0 auto;
}

.md_page {
  @include Text;
  @extend .width;

  ul {
    li {
      list-style: circle;
      list-style-position: inside;
    }
  }

  ol {
    li {
      list-style: auto;
    }
  }

  a {
    color: $blue;
  }
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

.ocean {
  @include oceanAnimation;
}

.popup-content {
  border-radius: 1em;
  width: 98% !important;

  @include media(">tablet") {
    width: 50% !important;
  }
}

.srOnly {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
}

@font-face {
  src: url("../public/font/Causten/Causten-Regular.otf");
  font-family: "Causten";
  font-weight: normal;
}

@font-face {
  src: url("../public/font/Causten/Causten-Bold.otf");
  font-family: "Causten";
  font-weight: bold;
}

@font-face {
  src: url("../public/font/Causten/Causten-Light.otf");
  font-family: "Causten";
  font-weight: lighter;
}

@font-face {
  src: url("../public/font/Causten/Causten-SemiBold.otf");
  font-family: "Causten";
  font-weight: 600;
}

@font-face {
  src: url("../public/font/Causten/Causten-ExtraBold.otf");
  font-family: "Causten";
  font-weight: 800;
}
