@import "@cs/styles";

.trigger {
  display: flex;
  align-items: center;
  cursor: pointer;
  color: $desatDarkBlue;
  border-radius: 5px;
}

.content {
  display: flex;
  flex-direction: column;
  padding-bottom: 40px;
}

.heading {
  display: flex;
  align-items: center;
  margin: 0;
}

.navigationContainer {
  padding: 16px;
}

.navigationList {
  display: flex;
  flex-direction: column;
  gap: 2px;
  margin-bottom: 18px;
}

.subHeading {
  font-size: 16px;
  font-weight: 400;
  color: $purple4;
  margin-bottom: 6px;
}

.link {
  font-size: 20px;
  font-weight: 500;
  border-radius: 6px;
}
