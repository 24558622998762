@import "@cs/styles";

.trigger {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  gap: 4px;
}

.content {
  z-index: 999;
}
