@import "@cs/styles";

.button {
  font-family: "Work Sans", "Roboto", sans-serif;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  gap: 6px;
  padding: 1rem 2rem;
  border-radius: 5px;
  font-weight: 500;
  font-size: 1.1rem;
  line-height: 20px;
  color: #fff;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  margin: 0 auto;
  border-radius: 5px;
  background: linear-gradient(90deg, #110f27 0%, $mediumBlue 100%);
  text-decoration: none;

  &:hover {
    background: linear-gradient(90deg, $mediumBlue 0%, $mediumBlue 100%);
    transform: scale(1.02);
  }

  &:active {
    background: linear-gradient(90deg, $darkBlue 0%, $darkBlue 100%);
  }

  @include media(">tablet") {
    font-size: 1.2rem;
  }
}

.light {
  @extend .button;

  background: #fff;
  border: 2px solid $mediumBlue;
  color: $mediumBlue;

  &:hover {
    color: $mediumBlue;
    background: #fff;
  }
}

.underline {
  @extend .button;
  padding: 0;
  color: $mediumBlue;
  background: inherit;
  transition: none;
  border: none;

  &:hover {
    text-decoration: underline;
    color: $mediumBlue;
    background: inherit;
    transform: none;
  }
}

.block {
  width: 100%;
}

.disabled {
  background: grey !important;
  cursor: default;

  &:hover {
    transform: none;
  }
}
