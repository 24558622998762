@import "@cs/styles";

.overlay {
  position: fixed;
  inset: 0;
  z-index: 99;
  background: rgba(0, 0, 0, 0.8);
  backdrop-filter: blur(4px);

  &[data-state="open"] {
    animation: overlayShow 200ms ease;
  }
}

.content {
  font-family: "Work Sans", "Roboto", sans-serif;
  position: fixed;
  right: 0px;
  top: 0px;
  bottom: 0px;
  z-index: 99;
  width: 300px;
  height: 100vh;
  background-color: white;
  padding: 14px;
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.2);

  &[data-state="open"] {
    animation: contentShow 300ms ease-out;
  }

  &[data-state="closed"] {
    animation: contentHide 300ms ease;
  }
}

.closeButton {
  position: absolute;
  display: flex;
  right: 16px;
  top: 26px;
  color: $desatDarkBlue;
}

.header {
  display: flex;
  flex-direction: column;
  gap: 16px;
  text-align: left;
}

.title {
  font-size: 1.5rem;
}

@keyframes overlayShow {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes contentShow {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}

@keyframes contentHide {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(100%);
  }
}
