@import "./colours.scss";

@mixin transition {
  transition: all 200ms ease-in;
}

@mixin slideUnderline($colour: $purple4) {
  position: relative;
  display: inline-block;
  &:before {
    content: "";
    position: absolute;
    width: 0;
    height: 3px;
    bottom: 0;
    left: 0;
    background-color: $colour;
    color: $colour;
    transition: all 0.3s ease-in-out;
  }

  &:hover:before {
    width: 100%;
  }
}

@mixin oceanAnimation {
  position: relative;
  &:before {
    content: "";
    position: absolute;
    z-index: -100;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(270deg, #5368ff, #6c7dfe 45.83%, #4a4dbb);
    background-size: 400% 400%;

    animation: AnimationGradient 15s ease infinite;
    @keyframes AnimationGradient {
      0% {
        background-position: 0% 50%;
      }
      50% {
        background-position: 100% 50%;
      }
      100% {
        background-position: 0% 50%;
      }
    }
  }
}

@mixin hide {
  display: none !important;
  visibility: hidden !important;
  position: absolute !important;
  height: 0 !important;
  width: 0 !important;
  opacity: 0 !important;
  z-index: -100000 !important;
}

@mixin shimmer {
  animation-duration: 1.8s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: #f2f3f5;
  background-image: linear-gradient(to right, #f6f7f8 0%, #ededf1 20%, #f6f7f8 40%, #f6f7f8 100%);
  background-size: 1000px 640px;
  border-radius: 3px;

  position: relative;

  @keyframes placeHolderShimmer {
    0% {
      background-position: -468px 0;
    }
    100% {
      background-position: 468px 0;
    }
  }
}

@mixin Text($var: "") {
  line-height: 1.5em;

  @if $var != "" {
    font-weight: $var;
  } @else {
    font-weight: 400;
  }
}

@mixin scrollbars(
  $size,
  $foreground-color,
  $background-color: mix($foreground-color, white, 50%),
  $rounded: false
) {
  & {
    position: relative;
  }

  // For Google Chrome
  &::-webkit-scrollbar {
    width: $size;
    height: $size;
  }

  &::-webkit-scrollbar-thumb {
    background: $foreground-color;
    box-shadow: inset 2px 2px 2px hsl(0deg 0% 100% / 25%), inset -2px -2px 2px rgb(0 0 0 / 25%);
    border-radius: 59px;
  }

  &::-webkit-scrollbar-track {
    background: $background-color;
    @if $rounded {
      border-radius: 59px;
    }
  }

  // For Internet Explorer
  & {
    scrollbar-face-color: $foreground-color;
    scrollbar-track-color: $background-color;
  }
}

@mixin SideGradient($color1, $color2) {
  background: linear-gradient(104.7deg, $color1 0%, $color2 100.55%);
}
