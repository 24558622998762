@import "@cs/styles";

.trigger {
  padding: 8px 12px;
  user-select: none;
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  font-style: normal;
  line-height: 1;
  border-radius: 4px;
  color: $desatDarkBlue;
  background-color: white;

  &:hover {
    background-color: $purple0;
    color: $purple7;
  }
}

.content {
  font-family: "Work Sans", "Roboto", sans-serif;
  position: relative;
  overflow: hidden;
  border-radius: 10px;
  margin-top: 8px;
  padding: 8px;
  box-shadow: hsl(206 22% 7% / 35%) 0px 10px 38px -10px, hsl(206 22% 7% / 20%) 0px 10px 20px -15px;
  background-color: white;
  z-index: 50;

  &[data-state="open"] {
    animation: fadeIn 200ms ease;
  }
}

.label,
.item {
  color: $darkerText;
  font-size: 16px;
  font-weight: 400;
  padding: 8px 8px 8px 28px;
}

.item {
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
  line-height: 1;
  border-radius: 4px;

  &:hover,
  &:focus {
    outline: none;
    color: $purple7;
    background-color: $purple0;
  }

  &[data-disabled] {
    pointer-events: none;
    color: $lightGrey;
  }
}

.itemIndicator {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 4px;
  height: 20px;
  width: 20px;
}

.separator {
  height: 1px;
  margin-block: 6px;
  background-color: $purple0;
}
