@import "@cs/styles";

.footer {
  background-color: white;
  min-height: 10em;
}

.innerFooter {
  max-width: var(--width);
  margin: 0 auto;
  color: black;
  display: flex;
  flex-wrap: wrap;

  padding: 35px 10px 60px;

  @include media(">2000px") {
    max-width: 2000px;
    width: 100%;
    margin: auto;
    padding: 33px 10px 60px;
    flex-direction: row;
    justify-content: space-around;
  }

  @include media(">=desktop", "<2000px") {
    max-width: 1440px;
    width: 100%;
    margin: auto;
    padding: 33px 10px 60px;
    flex-direction: row;
    justify-content: space-around;
  }

  @include media("<desktop") {
    justify-content: center;
    gap: 1rem;
  }

  .section {
    margin: 0.8em 7px;
    width: 150px;

    @include media("<desktop") {
      min-width: 25%;
    }

    @include media("<tablet") {
      text-align: center;
      margin-bottom: 2em;
      min-width: 100%;
    }

    span {
      font-size: 1.15em;
      font-weight: 500;
      margin: 12px 0 9px;
      text-transform: uppercase;

      @include media(">2000px") {
        font-size: 2em;
      }
    }

    .subsections {
      list-style: none;
      padding-left: 0;
      padding-right: 0;

      @include media(">=tablet", "<desktop") {
        display: flex;
        flex-direction: column;
      }

      li {
        @include Text;
        font-size: 0.95em;
        margin: 0.35em 0 0.5em;

        @include media(">2000px") {
          font-size: 1.3em;
        }

        @include media(">=desktop", "<1440px") {
          font-size: 0.87em;
        }

        @include media("<tablet") {
          font-size: 1.1em;
        }
      }

      &.unibody {
        flex-direction: column;

        li {
          margin: 0.35em 0;
        }
      }
    }
  }
}

.footerLine {
  width: 50%;
  margin: auto;
}

.copyright {
  text-align: center;
}
