.container {
  display: flex;
  align-items: center;
  gap: 16px;
}

.signUp {
  padding: 8px 10px !important;
}

.signIn,
.signUp {
  font-size: 16px !important;
}
