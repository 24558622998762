@import "@cs/styles";

.avatar {
  position: relative;
  display: flex;
  height: 40px;
  width: 40px;
  flex-shrink: 0;
  overflow: hidden;
  border-radius: 999px;
}

.avatarImage {
  aspect-ratio: 1;
  height: 100%;
  width: 100%;
}

.avatarFallback {
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  background: #f5f5f5;
  border-radius: 999px;
}
