@import "@cs/styles";

.wrapper {
  position: relative;
}

.image {
  position: absolute;
  top: -99px;
  right: 20px;
}

.btn {
  border: 1px solid $cs-blue-2;
  border-radius: 6px;
  color: $cs-blue-2;
  padding: 10px 20px;
  margin: 10px 20px 10px 0;
  cursor: pointer;

  &:focus {
    outline: none;
  }
}

.inactive {
  background-color: $cs-blue-2;
  color: white;
}

.active {
  background-color: $cs-grey;
}

.disabled {
  cursor: not-allowed;
}

.greenBtn {
  border: 1px solid $cs-green-2;
  border-radius: 99px;
  color: $cs-green-2;
  padding: 10px 20px;

  &:hover {
    background-color: $cs-green-2;
    color: white;
  }
}

.banner {
  position: fixed;
  bottom: 4px;
  margin-inline: 4px;
  padding: 20px;
  border-radius: 15px;
  background-color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  max-width: 700px;

  @include media(">tablet") {
    bottom: 30px;
    right: 30px;
  }
}

.description {
  line-height: 1.2;
  font-size: 1rem;

  @include media(">tablet") {
    font-size: 1.1rem;
  }
}

.link {
  color: $blue;
  cursor: pointer;
  margin: 0;
  padding: 0;
}

.cookieTable {
  text-align: left;
  border: 1px solid $cs-blue-2;
  border-radius: 10px;
  border-spacing: 0;
}

.settings {
  height: 50vh;
  @include media(">tablet") {
    height: auto;
  }
}

.cookieTable tr,
.cookieTable td {
  padding: 4px;
  border: 1px solid $cs-blue-2;
  font-size: 15px;
}

.cookieTable th {
  background-color: #f2f2f2;
}

.cookieTable tr:first-child td:first-child {
  border-top-left-radius: 8px; /* Rounds the top-left corner of the first cell in the first row */
}

.cookieTable tr:first-child td:last-child {
  border-top-right-radius: 8px; /* Rounds the top-right corner of the last cell in the first row */
}

.cookieTable tr:last-child td:first-child {
  border-bottom-left-radius: 8px; /* Rounds the bottom-left corner of the first cell in the last row */
}

.cookieTable tr:last-child td:last-child {
  border-bottom-right-radius: 8px; /* Rounds the bottom-right corner of the last cell in the last row */
}

.radioContainer {
  display: flex;
  align-items: center;
  margin-bottom: 22px; /* Example margin */
}

.radioInput {
  margin: 0 10px 0 0; /* Space before the label */
  cursor: pointer;
  /* Increase the size of the radio buttons */
  width: 24px; /* Example larger size */
  height: 24px; /* Example larger size */
  -webkit-appearance: none; /* Removes default system appearance */
  appearance: none;
  background-color: #f0f0f0; /* Light grey background */
  border: 1px solid #d1d1d1; /* Light grey border */
  border-radius: 50%; /* Circular border */
}

.radioInput:checked {
  background-color: $mediumBlue; /* Green background when checked */
  border-color: $mediumBlue;
}

.radioInput:focus {
  outline: none; /* Removes default focus outline */
  box-shadow: $cs-blue-3; /* Adds focus outline */
}

.radioLabel {
  margin-right: 20px; /* Space after the label before the next radio button */
  font-size: 16px;
  color: #333;
}

.radioLabel:last-child {
  margin-right: 0; /* No margin right for the last label */
}
