@import "@cs/styles";

.root {
  display: flex;
  justify-content: center;
  font-family: "Work Sans", "Roboto", sans-serif;
  position: relative;
  z-index: 999;
}

.list {
  display: flex;
  justify-content: center;
  gap: 8px;
  border-radius: 6px;
}

.item {
  display: flex;
  justify-content: center;
}

.trigger,
.menuLink {
  padding: 8px 12px;
  user-select: none;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  line-height: 1;
  border-radius: 4px;
  color: $desatDarkBlue;
  background-color: white;

  &:hover {
    background-color: $purple0;
    color: $purple7;
  }

  @include media(">tablet") {
    font-size: 16px;
    font-weight: 600;
  }
}

.trigger {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
}

.menuLink {
  display: block;
  text-decoration: none;
}

.chevron {
  position: relative;
  height: 9px;
  width: 9px;
  transition: transform 250ms ease;
}
[data-state="open"] > .chevron {
  transform: rotate(-180deg);
}

.content {
  position: absolute;
  top: 0;
  left: 0;
  transform-origin: top center;
  padding: 4px;
  width: 100%;
  background-color: white;
  border-radius: 6px;
  overflow: hidden;
  animation-duration: 120ms;
  animation-timing-function: ease;

  &[data-motion="from-start"] {
    animation-name: enterFromLeft;
  }
  &[data-motion="from-end"] {
    animation-name: enterFromRight;
  }
  &[data-motion="to-start"] {
    animation-name: exitToLeft;
  }
  &[data-motion="to-end"] {
    animation-name: exitToRight;
  }
}

.indicator {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  height: 10px;
  top: 100%;
  overflow: hidden;
  z-index: 1;
  transition: width, transform 250ms ease;

  &[data-state="visible"] {
    animation: fadeIn 200ms ease;
  }
  &[data-state="hidden"] {
    animation: fadeOut 200ms ease;
  }
}

.indicatorArrow {
  position: relative;
  top: 70%;
  background-color: white;
  width: 10px;
  height: 10px;
  transform: rotate(45deg);
  border-top-left-radius: 2px;
}

.viewportPosition {
  position: absolute;
  display: flex;
  justify-content: center;
  width: 100%;
  top: 100%;
  perspective: 2000px;

  @include media(">tablet") {
    max-width: 410px;
  }
}

.viewport {
  position: relative;
  transform-origin: top center;
  margin-top: 10px;
  min-width: 450px;
  width: 100%;
  background-color: white;
  border-radius: 6px;
  overflow: hidden;
  height: var(--radix-navigation-menu-viewport-height);
  transition: width, height, 300ms ease;

  &[data-state="open"] {
    animation: scaleIn 200ms ease;
  }
  &[data-state="closed"] {
    animation: scaleOut 200ms ease;
  }

  @include media(">tablet") {
    box-shadow: hsl(206 22% 7% / 35%) 0px 10px 38px -10px, hsl(206 22% 7% / 20%) 0px 10px 20px -15px;
  }
}

.listItemLink {
  display: block;
  text-decoration: none;
  cursor: pointer;
  font-size: 15px;
  line-height: 1;
  padding: 13px;
  margin: 4px;
  outline: none;
  user-select: none;
  font-size: 14px;
  font-weight: 400;
  line-height: 1;
  border-radius: 4px;
  color: $purple7;
  background-color: white;

  &:hover,
  &:focus {
    outline: none;
    background-color: $purple0;
  }

  @include media(">tablet") {
    font-size: 16px;
    font-weight: 500;
    padding: 15px;
  }
}

.listItemTitle {
  margin-bottom: 6px;
}

.listItemDescription {
  font-weight: 300;
  color: $darkGrey;
}

@keyframes enterFromRight {
  from {
    opacity: 0;
    transform: translateX(100px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes enterFromLeft {
  from {
    opacity: 0;
    transform: translateX(-100px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes exitToRight {
  from {
    opacity: 1;
    transform: translateX(0);
  }
  to {
    opacity: 0;
    transform: translateX(100px);
  }
}

@keyframes exitToLeft {
  from {
    opacity: 1;
    transform: translateX(0);
  }
  to {
    opacity: 0;
    transform: translateX(-100px);
  }
}

@keyframes scaleIn {
  from {
    opacity: 0;
    transform: rotateX(-30deg) scale(0.9);
  }
  to {
    opacity: 1;
    transform: rotateX(0deg) scale(1);
  }
}

@keyframes scaleOut {
  from {
    opacity: 1;
    transform: rotateX(0deg) scale(1);
  }
  to {
    opacity: 0;
    transform: rotateX(-10deg) scale(0.95);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
