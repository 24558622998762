@import "@cs/styles";

.accountLink {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  font-weight: 500;
  letter-spacing: -0.025em;
  max-width: 200px;
}

.name {
  overflow: hidden;
  text-overflow: ellipsis;
}
