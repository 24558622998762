@import "@cs/styles";

.scrollArea {
  position: relative;
  overflow: hidden;
  padding-right: 10px;
}

.scrollViewport {
  height: 100%;
  width: 100%;
  border-radius: inherit;
}

.scrollBar {
  display: flex;
  touch-action: none;
  user-select: none;

  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;

  &.vertical {
    height: 100%;
    width: 10px;
    padding: 1px;
    border-left-width: 1px;
    border-left-color: transparent;
  }

  &.horizontal {
    height: 10px;
    padding: 1px;
    border-top-width: 1px;
    border-top-color: transparent;
  }
}

.scrollThumb {
  position: relative;
  flex: 1;
  border-radius: 999px;
  background-color: $lightGrey;
}
